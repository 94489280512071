<template>
  <table class="table">
    <thead>
      <tr>
        <template v-for="(column, index) in columns">
          <th :class="[column.class]" v-if="column.colspan > 0" :key="column.id || index" :colspan="column.colspan">{{ column.header }}</th>
          <template v-else-if="column.colspan === 0"></template>
          <th :class="[column.class]" v-if="!column.colspan" :key="column.id || index" :colspan="column.colspan">{{ column.header }}</th>
        </template>
      </tr>
    </thead>
    <tbody>
      <tr v-if="data.length === emptyData && !loading">
        <td
          colspan="40"
          class="no_information_td"
          :class="{ loading, empty: data.length === emptyData }"
          align="center"
        >
          <img class="imagen_error" src="@/assets/image/warnig-error.png" alt="Sin información">
          <p class="title has-text-centered">
            Aún No Hay Información <br>
            Para Mostrar
          </p>
      </td>
      </tr>
      <tr v-else-if="loading">
        <td
          colspan="40"
          :class="{ loading, empty: data.length === emptyData }"
          align="center"
        >
          <loading-component />
        </td>
      </tr>
      <template v-else v-for="(row, rowIndex) in data">
        <tr :key="composeTrKey(row, rowIndex)" @click="activate(rowIndex)" :class="{ 'is-selected' : activeTr == rowIndex }">
          <template v-for="(field, fieldIndex) in columns">
            <td :class="[field.class]" :key="composeTdKey(row, rowIndex, field, fieldIndex)" v-if="field.link">
              <router-link :to="createLink(field, row)">{{ printFieldData(field, row) }}</router-link>
            </td>
            <td :class="[field.class]" :key="composeTdKey(row, rowIndex, field, fieldIndex)" v-else>
              {{ printFieldData(field, row) }}
              <slot v-if="field.name" :name="field.name" :row="row"></slot>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import get from 'lodash/get'

export default {
  name: 'BaseTable',

  props: {
    columns: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    pagination: {
      type: Object
    }
  },

  components: {
    LoadingComponent: () => import('@/components/ui/Loading')
  },

  data () {
    return {
      emptyData: 0,
      activeTr: null
    }
  },

  methods: {
    composeTrKey (row, rowIndex) {
      return `${row.id}.${rowIndex}`
    },
    composeTdKey (row, rowIndex, field, fieldIndex) {
      if (row.id && field.id) return `${row.id}.${field.id}`
      if (row.id && !field.id) return `${row.id}.${fieldIndex}`
      if (!row.id && field.id) return `${rowIndex}.${field.id}`
      return `${rowIndex}.${fieldIndex}`
    },
    printFieldData (field, row) {
      if (field.name) return ''
      if (typeof field.accessor === 'function') return field.accessor(row)
      if (typeof field.accessor === 'string') return get(row, field.accessor, '')
      if (field.id) return get(row, field.id, '')
      return ''
    },
    createLink (field, row) {
      if (field.type === 'ancla') return `#${row.route}`
      if (field.type === 'link') {
        if (typeof field.route === 'function') return field.route(row)
        if (typeof field.route === 'string') return get(row, field.route, '')
        return ''
      }
    },
    activate (el) {
      this.activeTr = el
    }
  }
}
</script>
<style scoped>
  .title {
    font-size: 18px;
    line-height: 1.25;
    font-weight: normal;
    text-align: center;
    color: #b5b5c3;
  }
  .imagen_error {
    max-width: 100px;
    margin-bottom: 2.5rem;
  }
  .no_information_td {
    height: 50vh;
    vertical-align: middle;
    background-color: white;
  }
  table {
    border-collapse:collapse
  }
  tr {
    border: solid 1px #e1e5eb;
  }
  .link a {
    cursor: pointer;
    color: #658abf;
  }
  .link a:hover {
    cursor: pointer;
    color: var(--primary-green);
  }
  .table th {
    font-size: 16px;
    padding: 10px 15px;
  }
  .table td, .table th {
    border: none;
  }
  .table td {
    font-size: 16px;
    color: #404254;
    line-height: 1.25;
    letter-spacing: 0.16px;
    padding: 0.75em 1em;
  }
  .table tbody tr:not(.is-selected):nth-child(odd) {
    background-color: #F7F9FB;
  }
  .table tbody tr:hover {
    background-color: #DBEBF4 !important;
  }
  .table tr.is-selected {
    background-color: #DBEBF4;
    color: #404254;
    font-weight: 600;
  }
</style>
